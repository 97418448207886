<template>
  <div class="flex flex-col">
    <div v-if="withTitle" class="flex flex-row items-center">
      <span class="text-lg font-bold">{{ isNewClient ? 'Crear Cliente' : 'Actualizar Cliente' }}</span>
      <div class="ml-auto">
        <sa-icon-button with-bg iconClass="text-xl bx bx-x" @click="cancel" />
      </div>
    </div>
    <div class="py-2">
      <el-form
        :model="client"
        :rules="formRules"
        ref="clientForm"
        v-loading="loadingSavingClient"
      >
        <el-form-item prop="fullName">
          <span>Nombre completo</span>
          <el-input v-model="client.fullName" />
        </el-form-item>
        <el-form-item prop="email">
          <span>Email</span>
          <el-input v-model="client.email" />
        </el-form-item>
        <el-form-item prop="phone">
          <span>Teléfono</span>
          <sa-phone-country :model="client" />
        </el-form-item>
        <el-form-item prop="contactDNI">
          <span>Numero de Identificación</span>
          <el-input v-model="client.dni" />
        </el-form-item>
        <el-form-item prop="contactDescription">
          <span>Dirección</span>
          <el-input type="textarea" v-model="client.contactDescription" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="errorMessage">
      <span class="text-red-500">{{ errorMessage }}</span>
    </div>
    <div class="flex justify-end flex-row space-x-4">
      <sa-button type="secondary" class="text-right" @click="cancel">
        Cancelar
      </sa-button>
      <sa-button
        type="primary"
        class="text-right"
        @click="save"
        v-loading="loadingSavingClient"
      >
        Guardar
      </sa-button>
    </div>
  </div>
</template>

<script>
import ClientService from "@/services/ClientService";
import { ContactTypeEnum } from "@/constants";
import error from "@/mixins/error";

export default {
  name: "ClientForm",
  props: {
    client: {
      type: Object,
      default: () => ({
        id: null,
        fullName: '',
        email: '',
        phone: '',
        phoneCountryCodeId: null,
        contactType: ContactTypeEnum.Phone,
        contactDescription: '',
        sendEmailNotifications: false,
        dni: null,
      }),
    },
    isNewClient: Boolean,
    withTitle: Boolean,
  },
  mixins: [error],
  data() {
    return {
      formRules: {
        fullName: [{ required: true, message: "Captura nombre y apellido del cliente" }],
        phoneCountryCodeId: [{ required: true, message: "Captura el Código de Area del cliente" }],
        phone: [{ required: true, message: "Captura el teléfono del cliente" }],
      },
      errorMessage: null,
      loadingSavingClient: false,
    };
  },
  created() {
    if (!this.client.phoneCountryCodeId) {
      this.client.phoneCountryCodeId = this.$store.state.business.phoneCountryCodeId || null;
    }
  },
  methods: {
    cancel() {
      this.$emit("onCancel");
    },
    async save() {
      this.$refs["clientForm"].validate(async (isFormValid) => {
        if (!isFormValid) return;
        await this.saveClient();
      });
    },
    async saveClient() {
      try {
        this.loadingSavingClient = true;
        const payload = {
          ...this.client,
          email: this.client.email?.toLowerCase(),
          sendEmailNotifications: true,
          businessInfoId: this.$store.state.business.id,
        };
        const savedClient = !this.client.id
          ? await ClientService.create(payload)
          : await ClientService.update(this.client.id, payload);
        this.$emit("onSaved", savedClient);
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error);
      } finally {
        this.loadingSavingClient = false;
      }
    },
  },
};
</script>